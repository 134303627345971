import { configureStore } from "@reduxjs/toolkit";
import { AssisApi } from "../services/AssisApi";
import authSlice from "./slices/auth";

const store = configureStore({
  reducer: {
    [AssisApi.reducerPath]: AssisApi.reducer,
    auth: authSlice,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat([AssisApi.middleware]);
  },
});
export type RootState = ReturnType<typeof store.getState>;

export default store;
