import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Pagination,
  TextField,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import Logo from "../../global/images/logo.svg";
import "./style.sass";
import { ChangeEvent, ReactNode, useState } from "react";
import { useEffect } from "react";

export interface Props {
  items: string[];
  title: string;
  GroupName?: ReactNode | string;
  open: boolean;
  isLoading: boolean;
  handleClose?: () => void;
}

export default function SearchListItems({
  items,
  title,
  GroupName,
  open,
  isLoading,
  handleClose,
}: Props) {
  const [itemsAux, setItemsAux] = useState([] as string[]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (items) setItemsAux([...items]);
  }, [items]);

  const handleChange = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleSearch = () => {
    const searchElement = document.getElementById("search") as HTMLInputElement;
    const i = items.filter((v) => {
      return v.toUpperCase().includes(searchElement.value.toUpperCase());
    });
    if (searchElement) setItemsAux([...i]);
    else setItemsAux([...items]);
  };

  return (
    <Dialog
      fullWidth
      onClose={handleClose}
      open={open}
      id={"search-list-items"}
    >
      {isLoading ? (
        "LOADING"
      ) : (
        <>
          <DialogTitle>
            <Grid container>
              <Grid item xs={10}>
                {title}
              </Grid>
              <Grid textAlign={"end"} item xs={2}>
                Total: {items?.length}
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent sx={{ p: 5 }}>
            <Grid container spacing={1}>
              <Grid item xs={11}>
                <TextField
                  variant={"outlined"}
                  fullWidth
                  id={"search"}
                  // value={search}
                  // onChange={handleChangeSearch}
                />
              </Grid>
              <Grid item xs={1}>
                <Button variant="contained" onClick={handleSearch}>
                  <Search />
                </Button>
              </Grid>
              <Grid item xs={12}>
                {GroupName}
              </Grid>
              <Grid item xs={12}>
                <List sx={{ pt: 0 }}>
                  {itemsAux
                    .slice((page - 1) * 10, page * 10)
                    .map((item, index) => (
                      <>
                        <ListItem key={index}>
                          <ListItemText primary={item} />
                        </ListItem>
                        <Divider />
                      </>
                    ))}
                </List>
              </Grid>
              <Grid item xs={12}>
                <Pagination
                  count={Math.ceil(itemsAux.length / 10)}
                  color="primary"
                  showFirstButton
                  showLastButton
                  page={page}
                  onChange={handleChange}
                  id={"sli-pagination"}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}
