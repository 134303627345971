import Screen from "../../components/Screen";
import "./styles.sass";
import {
  Typography,
  Tab,
  Tabs,
  Grid,
  Paper,
  Fab,
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import { SyntheticEvent } from "react";
import MetricBlock from "../../components/MetricBlock";
import AnalysisResultCourse from "../../components/AnalysisResultCourse";

import CourseIcon from "@mui/icons-material/MenuBook";
import PermContactCalendar from "@mui/icons-material/PermContactCalendar";
import TutorIcon from "@mui/icons-material/School";
import StudentIcon from "@mui/icons-material/Person";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import WarningIcon from "@mui/icons-material/Warning";
import GroupsIcon from "@mui/icons-material/Groups";
import DonutChart from "../../components/DonutChart";
import InfoIcon from "@mui/icons-material/CheckCircle";
import MoreAttentionIcon from "@mui/icons-material/CrisisAlert";

import {
  useEvasionMutation,
  useMetricsMutation,
  useIndicatorsMutation,
  useSuggestionsMutation,
  useGetCoursesQuery,
  useGetBatchQuery,
} from "../../services/AssisApi";
import AnalysisResultContent from "../../components/AnalysisResultContent";
import AnalysisResultRank from "../../components/AnalysisResultRank";
import { useParams } from "react-router-dom";

interface Props {}

export default function AnalysisResult({}: Props) {
  const { batchId } = useParams();

  const [tabValue, setTabValue] = useState("1");
  const {
    data: batchData,
    error: batchDataError,
    isLoading: batchDataIsLoading,
    isSuccess: batchDataIsSuccess,
  } = useGetBatchQuery(batchId as string);

  const [evasion, evasionResult] = useEvasionMutation();
  const [metrics, metricsResult] = useMetricsMutation();
  const [indicators, indicatorsResult] = useIndicatorsMutation();
  const [suggestions, suggestionsResult] = useSuggestionsMutation();
  const {
    data: courses,
    isLoading: coursesIsLoading,
    isSuccess: coursesIsSuccess,
  } = useGetCoursesQuery({
    batch: batchId as string,
  });

  const changeTab = async (e: SyntheticEvent, n: string) => {
    setTabValue(n);
  };

  useEffect(() => {
    (async (batchId) => {
      await evasion({ batchId: batchId as string });
      await suggestions({ batchId: batchId as string });
      await indicators({ batchId: batchId as string });
      await metrics({ batchId: batchId as string });
      // await getAllCourses({ batchId: "625f239be370b0398f60cc78" });
    })(batchId);
  }, []);

  return (
    <Screen>
      <div className="analysis-result-screen">
        <Grid container>
          <Grid item sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={changeTab}
              aria-label="basic tabs example"
              textColor="secondary"
            >
              <Tab
                icon={<InfoIcon fontSize="small" />}
                iconPosition="start"
                label="Informações Gerais"
                value="1"
              />
              <Tab
                icon={<MoreAttentionIcon />}
                iconPosition="start"
                label="Maior Atenção"
                value="2"
              />
              <Tab
                icon={<CourseIcon />}
                iconPosition="start"
                label="Cursos"
                value="3"
              />
            </Tabs>
          </Grid>

          <Grid item xs={12} className="content">
            {(batchDataIsLoading ||
              evasionResult.isLoading ||
              metricsResult.isLoading ||
              indicatorsResult.isLoading ||
              suggestionsResult.isLoading ||
              coursesIsLoading) && (
              <CircularProgress className="loading" size={48} />
            )}

            {tabValue === "1" &&
              batchDataIsSuccess &&
              evasionResult?.data &&
              suggestionsResult?.data &&
              indicatorsResult?.data &&
              metricsResult?.data && (
                <AnalysisResultContent
                  courseCount={evasionResult.data.courseCount}
                  tutorCount={evasionResult.data.tutorCount}
                  studentCount={evasionResult.data.studentCount}
                  evasionData={evasionResult.data}
                  suggestionsData={suggestionsResult.data}
                  indicatorsData={indicatorsResult.data}
                  metricsData={metricsResult.data}
                />
              )}

            {tabValue === "2" && batchDataIsSuccess && evasionResult?.data && (
              <Grid
                spacing={2}
                container
                // justifyContent={"center"}
                sx={{ marginTop: "10px" }}
              >
                {evasionResult.data.courseRanking.length && (
                  <Grid item xs={8}>
                    <AnalysisResultRank
                      data={evasionResult.data.courseRanking}
                      type={"course"}
                    />
                  </Grid>
                )}
                {evasionResult.data.tutorRanking.length && (
                  <Grid item xs={8}>
                    <AnalysisResultRank
                      data={evasionResult.data.tutorRanking}
                      type={"tutor"}
                    />
                  </Grid>
                )}
                {evasionResult.data.studentRanking.length && (
                  <Grid item xs={8}>
                    <AnalysisResultRank
                      data={evasionResult.data.studentRanking}
                      type={"student"}
                    />
                  </Grid>
                )}
              </Grid>
            )}

            {tabValue === "3" &&
              coursesIsSuccess &&
              courses.map((course) => (
                <AnalysisResultCourse
                  batchId={batchId as string}
                  course={course}
                />
              ))}
          </Grid>
        </Grid>
      </div>
    </Screen>
  );
}
