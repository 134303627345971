import "./styles.sass";
import { Typography, Grid, Paper, Fab, Stack, Popover } from "@mui/material";
import { MouseEvent, useEffect, useState } from "react";
import MetricBlock from "../MetricBlock";

import MenuBookIcon from "@mui/icons-material/MenuBook";
import PermContactCalendar from "@mui/icons-material/PermContactCalendar";
import SchoolIcon from "@mui/icons-material/School";
import PersonIcon from "@mui/icons-material/Person";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import WarningIcon from "@mui/icons-material/Warning";
import GroupsIcon from "@mui/icons-material/Groups";
import DonutChart from "../DonutChart";
import InfoIcon from "@mui/icons-material/CheckCircle";
import LockIcon from "@mui/icons-material/Lock";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";

import {
  Analisys,
  Evasion,
  GetStudentsListByIdsResponse,
  Indicator,
  Metric,
  Suggestion,
} from "../../types";
import { useNavigate, useParams } from "react-router-dom";
import { InfoOutlined } from "@mui/icons-material";
import SearchListItems, { Props as SLIProps } from "../SearchListItems";
import { useGetStudentsListByIdsMutation } from "../../services/AssisApi";

interface Props {
  courseCount: number;
  tutorCount: number;
  studentCount: number;
  evasionData: Evasion;
  suggestionsData: Suggestion[];
  indicatorsData: Indicator[];
  metricsData: Metric[];
}

export default function AnalysisResultContent({
  courseCount,
  tutorCount,
  studentCount,
  evasionData,
  suggestionsData,
  indicatorsData,
  metricsData,
}: Props) {
  const navigate = useNavigate();
  const { batchId } = useParams();
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
  const [popoverText, setPopoverText] = useState<String>("");
  const [searchListItemsProps, setSearchListItemsProps] = useState<SLIProps>(
    {} as SLIProps
  );
  const [getStudentsList, { data, isSuccess }] =
    useGetStudentsListByIdsMutation();

  useEffect(() => {
    if (data && isSuccess)
      setSearchListItemsProps((old) => ({
        ...old,
        items: data.map((s: GetStudentsListByIdsResponse) => s.name),
        isLoading: false,
      }));
  }, [data]);

  const handlePlanClick = () => {
    navigate(`/plans`);
  };

  const handlePopoverOpen = (
    event: MouseEvent<SVGSVGElement>,
    text: String
  ) => {
    setAnchorEl(event.currentTarget);
    setPopoverText(text);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleListStudents = async (params: {
    title: string;
    ids: number[];
  }) => {
    await getStudentsList({
      studentId: params.ids,
      batch: batchId || "",
    });
    setSearchListItemsProps({
      items: [],
      title: params.title,
      open: true,
      isLoading: true,
    });
  };

  const handleCloseSLI = () => {
    setSearchListItemsProps({
      items: [],
      title: "params.title",
      open: false,
      isLoading: false,
    });
  };

  const open = Boolean(anchorEl);
  return (
    <div className="analysis-result-content">
      <Grid container marginTop={2} spacing={2} item xs={12} p={2}>
        <Grid item xs={12}>
          <Paper elevation={2} className="summary">
            <Grid container padding={2} justifyContent={"center"}>
              <Grid container item lg={3} alignItems={"center"}>
                <Grid item lg={2}>
                  <MenuBookIcon />
                </Grid>
                <Grid item>
                  <Typography variant={"h6"}>{courseCount} Cursos</Typography>
                </Grid>
              </Grid>
              <Grid container item lg={3} alignItems={"center"}>
                <Grid item lg={2}>
                  <SchoolIcon />
                </Grid>
                <Grid item>
                  <Typography variant={"h6"}>{tutorCount} Tutores</Typography>
                </Grid>
              </Grid>
              <Grid container item lg={3} alignItems={"center"}>
                <Grid item lg={2}>
                  <PersonIcon />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      handleListStudents({
                        title: "Todos os alunos",
                        ids: evasionData?.analisys.reduce<number[]>(
                          (prev, cur) => {
                            return [...prev, ...cur.studentIds];
                          },
                          []
                        ),

                        // [
                        //   evasionData?.analisys.length - 1
                        // ]["studentIds"],
                      })
                    }
                    variant={"h6"}
                  >
                    {studentCount} Alunos
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} marginTop={5}>
          <Paper elevation={2} className="recommendations">
            <Grid container spacing={5} padding={2} justifyContent={"center"}>
              <Grid item xs={12}>
                <Paper elevation={1}>
                  <Grid container padding={1} alignItems={"center"}>
                    <Grid item xs={3} lg={1}>
                      <Fab
                        disabled
                        className="white"
                        sx={{ color: `#e43b3b !important` }}
                      >
                        <WarningIcon />
                      </Fab>
                    </Grid>
                    <Grid item xs={9} lg={11} textAlign={"center"}>
                      <Typography
                        variant={"h6"}
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          handleListStudents({
                            title: "Altíssimo risco de evasão",
                            ids: evasionData?.analisys[
                              evasionData?.analisys.length - 1
                            ]["studentIds"],
                          })
                        }
                      >
                        {
                          evasionData?.analisys[
                            evasionData?.analisys.length - 1
                          ]["qty"]
                        }{" "}
                        alunos estão em altíssimo{" "}
                        <span className="red">risco de evasão</span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              {suggestionsData.length === 0 ? (
                <Grid item container xs={12}>
                  <Grid
                    container
                    item
                    xs={12}
                    className={"overlayer"}
                    textAlign={"center"}
                    justifyContent={"center"}
                  >
                    <Grid item xs={12}>
                      <LockIcon
                        onClick={handlePlanClick}
                        width={"100%"}
                        sx={{ fontSize: "150px", cursor: "pointer" }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        spacing={1}
                      >
                        <QuestionAnswerIcon />
                        <Typography variant={"h5"}>Recomendações</Typography>
                        <InfoOutlined
                          onMouseEnter={(e) =>
                            handlePopoverOpen(
                              e,
                              "Nas recomendações você verá quantos e quais alunos estão com altíssimo risco de evasão e receberá sugestões estratégicas para contornar essa situação"
                            )
                          }
                          onMouseLeave={handlePopoverClose}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant={"h5"}>
                        Este é um recurso premium. Atualize seu plano para mais
                        informações
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    className={"premium-limitation"}
                    container
                    item
                    xs={12}
                    lg={3}
                  >
                    <Grid item xs={12}>
                      <DonutChart
                        data={[
                          ["Alunos", "Quantidade"],
                          ["Indicados", 50],
                          ["Não Indicados", 50],
                        ]}
                        height="150px"
                      />
                    </Grid>
                    <Grid item xs={12} textAlign={"center"}>
                      <Typography variant={"subtitle2"}>??? alunos</Typography>
                    </Grid>
                    <Grid item xs={12} textAlign={"center"}>
                      <Typography variant={"h6"}>
                        Sugestão importante para mitigar a evasão
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      textAlign={"center"}
                      className="donut-text"
                    >
                      <Typography variant={"h6"}>50%</Typography>
                    </Grid>
                  </Grid>
                  <Grid className={"premium-limitation"} item xs={12} lg={9}>
                    <Paper className="recommendation-card">
                      <div className="recommendation-card-header">
                        <Typography variant="body1">Problema</Typography>
                      </div>
                      <Paper className="recommendation-card-body" elevation={0}>
                        <div className="recommendation-card-body-title">
                          <InfoIcon fontSize="small" className="icon" />
                          <Typography variant={"h6"}>Recomendação</Typography>
                        </div>
                        <Typography variant="body1">
                          Recomendação, exclusivo para usuário premium mitigarem
                          a evasão
                        </Typography>
                      </Paper>
                    </Paper>
                  </Grid>
                </Grid>
              ) : (
                suggestionsData.map((suggestion: Suggestion, key: number) => (
                  <Grid key={key} item container xs={12}>
                    <Grid container item xs={12} lg={3}>
                      <Grid item xs={12}>
                        <DonutChart
                          data={[
                            ["Alunos", "Quantidade"],
                            ["Indicados", suggestion.percent],
                            ["Não Indicados", 100 - suggestion.percent],
                          ]}
                          height="150px"
                        />
                      </Grid>
                      <Grid item xs={12} textAlign={"center"}>
                        <Typography
                          sx={{ cursor: "pointer" }}
                          onClick={() =>
                            handleListStudents({
                              title: suggestion.name,
                              ids: suggestion.studentIds,
                            })
                          }
                          variant={"subtitle2"}
                        >
                          {suggestion.studentCount} alunos
                        </Typography>
                      </Grid>
                      <Grid item xs={12} textAlign={"center"}>
                        <Typography variant={"h6"}>
                          {suggestion.name}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        textAlign={"center"}
                        className="donut-text"
                      >
                        <Typography variant={"h6"}>
                          {suggestion.percent}%
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                      <Paper className="recommendation-card">
                        <div className="recommendation-card-header">
                          <Typography variant="body1">
                            {suggestion.problem}
                          </Typography>
                        </div>
                        <Paper
                          className="recommendation-card-body"
                          elevation={0}
                        >
                          <div className="recommendation-card-body-title">
                            <InfoIcon fontSize="small" className="icon" />
                            <Typography variant={"h6"}>Recomendação</Typography>
                          </div>
                          <Typography variant="body1">
                            {suggestion.recommendations}
                          </Typography>
                        </Paper>
                      </Paper>
                    </Grid>
                  </Grid>
                ))
              )}
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={2}>
            <Grid container padding={2}>
              <Grid item xs={12} display={"flex"}>
                <GroupsIcon sx={{ height: "100%" }} />
                <Typography variant={"h6"}>
                  Grupos de risco de evasão (Média Geral)
                </Typography>
              </Grid>
              {evasionData.analisys.map(
                (analisysItem: Analisys, key: number) => (
                  <Grid item xs={6} lg={3} container marginY={5} key={key}>
                    <Grid item xs={4} display={"flex"}>
                      <Fab
                        disabled
                        className="white"
                        sx={{ color: `${analisysItem.color} !important` }}
                      >
                        <WarningIcon />
                      </Fab>
                      <div
                        className={"color-bar"}
                        style={{ background: analisysItem.color }}
                      ></div>
                    </Grid>
                    <Grid container item xs={6} lg={8}>
                      <Grid item xs={12}>
                        <Stack direction={"row"} spacing={1}>
                          <Typography
                            sx={{ cursor: "pointer" }}
                            onClick={() =>
                              handleListStudents({
                                title: `${analisysItem.title} de evasão`,
                                ids: analisysItem.studentIds,
                              })
                            }
                            variant={"body1"}
                          >
                            <strong>{analisysItem.title}</strong>
                          </Typography>
                          <InfoOutlined
                            onMouseEnter={(e) =>
                              handlePopoverOpen(e, analisysItem.tooltip)
                            }
                            onMouseLeave={handlePopoverClose}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant={"caption"}>
                          {analisysItem.percent.toFixed(2)}%{" "}
                          <strong>({analisysItem.qty} alunos)</strong>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              )}
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={2}>
            <Grid container>
              <Grid item xs={12} padding={2} display={"flex"}>
                <PermContactCalendar sx={{ height: "100%" }} />
                <Typography variant={"h6"}>
                  Indicadores (Média Geral)
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                container
                padding={2}
                justifyContent={"center"}
              >
                {indicatorsData.length === 0 ? (
                  <Grid item container xs={12}>
                    <Grid
                      container
                      item
                      xs={12}
                      className={"overlayer"}
                      textAlign={"center"}
                      justifyContent={"center"}
                    >
                      <Grid item xs={12}>
                        <LockIcon
                          onClick={handlePlanClick}
                          width={"100%"}
                          sx={{ fontSize: "150px", cursor: "pointer" }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Stack
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          spacing={1}
                        >
                          <PermContactCalendar />
                          <Typography variant={"h5"}>Indicadores</Typography>
                          <InfoOutlined
                            onMouseEnter={(e) =>
                              handlePopoverOpen(
                                e,
                                "Os indicadores sinalizam por quais motivos os alunos estão propensos a evadir separando-os em 4 categorias: baixo rendimento, procrastinação, abandono e inatividade."
                              )
                            }
                            onMouseLeave={handlePopoverClose}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant={"h5"}>
                          Este é um recurso premium. Atualize seu plano para
                          mais informações
                        </Typography>
                      </Grid>
                    </Grid>
                    {[1, 2, 3, 4].map((el, key) => (
                      <Grid
                        className={"premium-limitation"}
                        container
                        item
                        lg={3}
                        key={key}
                      >
                        <Grid item xs={12}>
                          <DonutChart
                            data={[
                              ["Alunos", "Quantidade"],
                              ["Indicados", 50],
                              ["Não Indicados", 100 - 50],
                            ]}
                            height="150px"
                          />
                        </Grid>
                        <Grid item xs={12} textAlign={"center"}>
                          <Typography variant={"subtitle2"}>
                            ??? alunos
                          </Typography>
                        </Grid>
                        <Grid item xs={12} textAlign={"center"}>
                          <Typography variant={"h6"}>Indicator Name</Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          textAlign={"center"}
                          className="donut-text"
                        >
                          <Typography variant={"h6"}>{50}%</Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  indicatorsData.map((el: Indicator, key: any) => (
                    <Grid key={key} container item lg={3}>
                      <Grid item xs={12}>
                        <DonutChart
                          data={[
                            ["Alunos", "Quantidade"],
                            ["Indicados", el.percent],
                            ["Não Indicados", 100 - el.percent],
                          ]}
                          height="150px"
                        />
                      </Grid>
                      <Grid item xs={12} textAlign={"center"}>
                        <Typography
                          sx={{ cursor: "pointer" }}
                          onClick={() =>
                            handleListStudents({
                              title: el.name,
                              ids: el.studentIds,
                            })
                          }
                          variant={"subtitle2"}
                        >
                          {el.studentCount} alunos
                        </Typography>
                      </Grid>
                      <Grid item xs={12} textAlign={"center"}>
                        <Stack
                          direction={"row"}
                          spacing={1}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Typography variant={"h6"}>{el.name}</Typography>
                          <InfoOutlined
                            onMouseEnter={(e) =>
                              handlePopoverOpen(e, el.tooltip)
                            }
                            onMouseLeave={handlePopoverClose}
                          />
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        textAlign={"center"}
                        className="donut-text"
                      >
                        <Typography variant={"h6"}>{el.percent}%</Typography>
                      </Grid>
                    </Grid>
                  ))
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={2}>
            <Grid container>
              <Grid item xs={12} padding={2} display={"flex"}>
                <SignalCellularAltIcon sx={{ height: "100%" }} />
                <Typography variant={"h6"}>Métricas (Média Geral)</Typography>
              </Grid>
              {metricsData.length === 0 ? (
                <Grid item container xs={12}>
                  <Grid
                    container
                    item
                    xs={12}
                    className={"overlayer"}
                    textAlign={"center"}
                    justifyContent={"center"}
                  >
                    <Grid item xs={12}>
                      <LockIcon
                        onClick={handlePlanClick}
                        width={"100%"}
                        sx={{ fontSize: "150px", cursor: "pointer" }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        spacing={1}
                      >
                        <SignalCellularAltIcon />
                        <Typography variant={"h5"}>
                          Métricas comportamentais
                        </Typography>
                        <InfoOutlined
                          onMouseEnter={(e) =>
                            handlePopoverOpen(
                              e,
                              "Tempo online na plataforma, persistência nas atividades, compreensão do conteúdo e precedência nas avaliações. Todos esses tópicos detalhados com dados para você entender melhor as possíveis causa da evasão."
                            )
                          }
                          onMouseLeave={handlePopoverClose}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant={"h5"}>
                        Este é um recurso premium. Atualize seu plano para mais
                        informações
                      </Typography>
                    </Grid>
                  </Grid>
                  {[1, 2].map((el, key) => (
                    <Grid
                      className={"premium-limitation"}
                      container
                      item
                      lg={6}
                      key={key}
                    >
                      <Grid key={key} item xs={12} lg={4}>
                        <MetricBlock
                          title={"Metric name"}
                          subtitle={"Metric description"}
                          classifications={[
                            {
                              title: "Baixo risco",
                              color: "#3be453",
                              studentIds: [],
                              total: 0,
                              percent: 0,
                              avg: 0,
                            },
                            {
                              title: "Médio risco",
                              color: "#ffff00",
                              studentIds: [],
                              total: 0,
                              percent: 0,
                              avg: 0,
                            },
                            {
                              title: "Alto risco",
                              color: "#E4913B",
                              studentIds: [],
                              total: 0,
                              percent: 0,
                              avg: 0,
                            },
                            {
                              title: "Altíssimo risco",
                              color: "#e43b3b",
                              studentIds: [null, null, null],
                              total: 3,
                              percent: 100,
                              avg: 0,
                            },
                          ]}
                        />
                      </Grid>
                      <Grid lg={2}></Grid>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                metricsData.map((metric: Metric, key: number) => (
                  <>
                    <Grid key={key} item xs={12} lg={4}>
                      <MetricBlock
                        {...metric}
                        handlePopoverOpen={handlePopoverOpen}
                        handlePopoverClose={handlePopoverClose}
                        handleListStudents={handleListStudents}
                      />
                    </Grid>
                    <Grid lg={2}></Grid>
                  </>
                ))
              )}
            </Grid>
          </Paper>
        </Grid>
        <Popover
          sx={{
            pointerEvents: "none",
          }}
          id="popover"
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1 }}>{popoverText}</Typography>
        </Popover>
        <SearchListItems
          {...searchListItemsProps}
          GroupName={
            <Stack direction={"row"} spacing={1}>
              <PersonIcon />
              <Typography fontWeight={500}>Alunos</Typography>
            </Stack>
          }
          handleClose={handleCloseSLI}
        />
      </Grid>
    </div>
  );
}
