import {
  Breadcrumbs as BreadcrumbsMUI,
  Button,
  Typography,
} from "@mui/material";
import { ArrowRight as BreadcrumbSeparatorIcon } from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import "./styles.sass";
import { useNavigate, useResolvedPath } from "react-router-dom";
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";

const routes = [
  {
    path: "/i",
    breadcrumb: "Ambientes cadastrados",
    props: { icon: <HomeIcon fontSize="small" /> },
  },
  { path: "/i/:id/analises", breadcrumb: "Análises" },
  { path: "/i/:id/analises/new", breadcrumb: "Nova análise" },
  { path: "/i/:id/settings", breadcrumb: "Configuração do ambiente" },
  {
    path: "/i/:id/analises/:batchId/result",
    breadcrumb: "Resultado da análise",
  },

  {
    path: "/user/settings",
    breadcrumb: "Configurações do usuário",
  },

  {
    path: "/invoices",
    breadcrumb: "Faturamento e pagamento",
  },
  {
    path: "/plans",
    breadcrumb: "Selecione um plano",
  },
];

// interface Props {}

export default function Breadcrumbs() {
  const navigate = useNavigate();
  const resolvedPath = useResolvedPath("");
  const breadcrumbs = useReactRouterBreadcrumbs(routes, {
    disableDefaults: true,
  });

  return (
    <BreadcrumbsMUI separator={<BreadcrumbSeparatorIcon fontSize="small" />}>
      {/* {routes} */}
      {/* {breadcrumbs.map(({ breadcrumb }) => breadcrumb)} */}

      {breadcrumbs.map(({ match, breadcrumb }, key) => {
        if (match.pathname === resolvedPath.pathname)
          return (
            <Typography variant="subtitle2" key={key}>
              {breadcrumb}
            </Typography>
          );
        else
          return (
            <Button
              key={key}
              size="small"
              onClick={() => {
                navigate(match.pathname);
              }}
            >
              {breadcrumb}
            </Button>
          );
      })}

      {/* <Router breadcrumbsMode={true}/> */}
      {/* {paths.map((path, key) => {
        return path.link ? (
          <Button size="small" key={key}>{path.title}</Button>
        ) : (
          <Typography variant="subtitle2" key={key}>{path.title}</Typography>
        );
      })} */}
    </BreadcrumbsMUI>
  );
}
