import {
  PaperProps,
  ButtonBase,
  IconButton,
  Typography,
  ButtonProps,
  Paper,
} from "@mui/material";
import {
  Settings as SettingsInstanceIcon,
  ContentCopy as CopyTokenIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import "./styles.sass";
import { useSnackbar } from "notistack";

interface Props extends ButtonProps {
  id: string;
  title: string;
  token: string;
}

export default function InstanceButton({ id, title, token, onClick }: Props) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <div className="instance-button">
      <ButtonBase className="button" onClick={onClick}>
        <div className="body">
          <Typography className="title" variant="subtitle2">
            {title}
          </Typography>
        </div>
        <div className="footer">
          <Typography className="title" variant="subtitle2">
            Token
          </Typography>
          <Typography className="token" variant="caption">
            {token}
          </Typography>
        </div>
      </ButtonBase>

      <IconButton
        className="settingsButton"
        size="small"
        color="primary"
        onClick={() => {
          navigate(`${id}/settings`, {
            state: {
              description: title,
            },
          });
        }}
      >
        <SettingsInstanceIcon fontSize="small" />
      </IconButton>
      <IconButton
        className="copyButton"
        size="small"
        color="primary"
        onClick={() => {
          navigator.clipboard.writeText(token);
          enqueueSnackbar("Token copiado!", {
            variant: "success",
          });
        }}
      >
        <CopyTokenIcon fontSize="small" />
      </IconButton>
    </div>
  );
}
