import {
  Button,
  ButtonBase,
  Dialog,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import {
  AddCircleOutline as AddInstanceIcon,
  SpaRounded,
} from "@mui/icons-material";

import "./styles.sass";
import InstanceButton from "../../components/InstanceButton";
import Screen from "../../components/Screen";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetIntancesQuery } from "../../services/AssisApi";
import { TourProvider, useTour } from "@reactour/tour";
import { useEffect, useState } from "react";
import tourImage from "./images/tour.svg";
import pHeaderImage from "./images/premium-header.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface Props {}

export default function Instances({}: Props) {
  const userInfo = useSelector(
    (state: RootState) => state.auth.authenticatedUser
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [dialog, setDialog] = useState(false);
  const [dialogPremium, setDialogPremium] = useState(false);
  const { setIsOpen } = useTour();
  const {
    data: instances,
    // error: getInstancesError,
    // isLoading: getInstancesIsLoading,
    // refetch,
  } = useGetIntancesQuery(undefined, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    setDialog(userInfo.isFirstAccess);
    setDialogPremium(userInfo.isNewPremium);
  }, [userInfo]);

  return (
    <>
      <Dialog open={dialog} maxWidth="sm">
        <Grid container>
          <Grid xs={4}></Grid>
          <Grid xs={4} sx={{ marginTop: 3 }}>
            <img src={tourImage} alt="tour image" />
          </Grid>
          <Grid xs={4}></Grid>
          <Grid xs={3}></Grid>
          <Grid xs={6}>
            <Typography variant={"h5"} textAlign={"center"}>
              Seja bem-vindo ao Assis
            </Typography>
          </Grid>
          <Grid xs={3}></Grid>
          <Grid xs={1}></Grid>
          <Grid xs={10}>
            <Typography textAlign={"center"}>
              Que tal começar com um tour pelas principais funcionalidades que
              vão te ajudar a conter a evasão?
            </Typography>
          </Grid>
          <Grid xs={1}></Grid>
          <Grid xs={2}></Grid>
          <Grid xs={8} sx={{ marginBottom: 1 }} textAlign={"center"}>
            <Button
              onClick={() => {
                setDialog(false);
                setIsOpen(true);
              }}
              variant={"contained"}
            >
              Vamos lá!
            </Button>
          </Grid>
          <Grid xs={2}></Grid>
          <Grid xs={2}></Grid>
          <Grid xs={8} textAlign={"center"}>
            <Button variant={"text"}>Não, obrigado</Button>
          </Grid>
          <Grid xs={2}></Grid>
        </Grid>
      </Dialog>
      <Dialog open={dialogPremium} maxWidth="sm" id={"dialog-premium"}>
        <Grid container spacing={2}>
          <Grid xs={4}></Grid>
          <Grid xs={4} sx={{ marginTop: 3 }}>
            <img src={pHeaderImage} alt="Premium Header Image" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h4"} textAlign={"center"}>
              Parabéns
            </Typography>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <Typography textAlign={"center"}>
              Agora você é um usuário{" "}
              <span className={"premium-color"}>PREMIUM</span>, e pode utilizar
              todos os recursos que o Assis oferece para mitigar a evasão.
            </Typography>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <Typography textAlign={"center"}>
              Clique no link para visualizar melhor as novas funcionalidades
            </Typography>
          </Grid>
          <Grid item xs={2}></Grid>
          {/* <Grid item xs={1}></Grid> */}
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent={"center"}>
              <Grid item xs={8}>
                <Stack direction={"row"} spacing={2}>
                  <CheckCircleIcon className={"checkCircle"} />
                  <Typography>Grupos de risco de evasão</Typography>
                </Stack>
              </Grid>
              <Grid item xs={8}>
                <Stack direction={"row"} spacing={2}>
                  <CheckCircleIcon className={"checkCircle"} />
                  <Typography>
                    Análises do risco de evasão ilimitadas
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={8}>
                <Stack direction={"row"} spacing={2}>
                  <CheckCircleIcon className={"checkCircle"} />
                  <Typography>Métricas comportamentais</Typography>
                </Stack>
              </Grid>
              <Grid item xs={8}>
                <Stack direction={"row"} spacing={2}>
                  <CheckCircleIcon className={"checkCircle"} />
                  <Typography>
                    Indicadores do pontencial risco de evasão
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={8}>
                <Stack direction={"row"} spacing={2}>
                  <CheckCircleIcon className={"checkCircle"} />
                  <Typography>Download de relatórios completos</Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={2}></Grid>
          <Grid
            xs={8}
            sx={{ marginBottom: 3, marginTop: 3 }}
            textAlign={"center"}
          >
            <Button
              onClick={() => {
                setDialogPremium(false);
              }}
              variant={"contained"}
            >
              Continuar para o sistema
            </Button>
          </Grid>
        </Grid>
      </Dialog>
      <div className="instances-screen">
        <Screen>
          <ButtonBase
            className="add-instance"
            onClick={() =>
              navigate("new", {
                state: {
                  backgroundLocation: location,
                },
              })
            }
          >
            <AddInstanceIcon className="icon" />
            <Typography variant="button">Criar ambiente</Typography>
          </ButtonBase>

          {instances?.map((instance, key) => (
            <InstanceButton
              key={key}
              id={instance._id}
              title={instance.description}
              token={instance.token}
              onClick={() => {
                navigate(`${instance._id}/analises`, {
                  state: {
                    pageTitle: instance.description,
                  },
                });
              }}
            />
          ))}

          {/* 
        <InstanceButton
          title="Instituto de educação infantil"
          token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c"
          onClick={() => {
            navigate("22es345432w3", {
              state: { pageTitle: "Instituto de educação infantil" },
            });
          }}
        />
        <InstanceButton
          title="Estácio Moreira Campos"
          token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c"
        />
        <InstanceButton
          title="UECE"
          token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c"
        /> */}
        </Screen>
      </div>
    </>
  );
}
