import {
  Button,
  Divider,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import "./styles.sass";
import Logo from "../../global/images/logo.svg";
import LoginForm from "../../components/LoginForm";
import SignupForm from "../../components/SignupForm";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface Props {}

export default function Login({}: Props) {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("1");

  return (
    <div className="login-screen">
      <div className="header">
        <img src={Logo} alt="Logo Assis" />
        <Divider className="divider" />
      </div>
      <div className="main">
        <Paper className="content" elevation={4}>
          <Tabs
            className="tabs"
            value={selectedTab}
            onChange={(event, value) => setSelectedTab(value)}
            centered
          >
            <Tab label="Entrar" value="1" />
            <Tab label="Cadastrar" value="2" />
          </Tabs>

          {selectedTab === "1" && <LoginForm />}
          {selectedTab === "2" && <SignupForm />}

          <Grid container spacing={2}>
            <Grid item xs={12} className="keep-connected-wrapper">
              <Button
                variant="text"
                onClick={() => navigate("/password-recovery")}
              >
                Esqueceu sua senha?
              </Button>
            </Grid>
            <Grid item xs={12} className="keep-connected-wrapper">
              <Typography variant="body2" align="center">
                Ao continuar, estou de acordo com os{" "}
                <a href="#">Termos de Uso e Política de Privacidade</a> do
                Assis.
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>
  );
}
