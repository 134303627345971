import {
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import "./styles.sass";
import Screen from "../../components/Screen";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect } from "react";
import axios from "axios";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { Search } from "@mui/icons-material";
import { useAcceptPlanMutation } from "../../services/AssisApi";
import LogoPagSeguro from "./images/logoPagseguro.svg";
import { useSnackbar } from "notistack";

interface Props {}

export default function Payment({}: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [acceptPlan, acceptPlanReponse] = useAcceptPlanMutation();

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Informe um email valido")
      .required("Informe seu email para a confirmação"),
    name: yup.string().required("Informe seu Nome"),
    phone: yup
      .string()
      .required("Informe o numero do celular")
      .matches(/^[0-9\s]+$/, "Apenas números")
      .min(11, "Deve ser exatamente ${min} números, 11999999999")
      .max(11, "Deve ser exatamente ${max} números, 11999999999"),
    street: yup
      .string()
      .required("Informe o nome da sua rua")
      .max(80, "Limite de ${max} caracteres"),
    number: yup
      .string()
      .required("Informe numero da sua casa")
      .matches(/^[0-9\s]+$/, "Apenas números")
      .max(20, "Limite de ${max} caracteres"),
    complement: yup
      .string()
      .required("Informe seu Nome")
      .max(40, "Limite de ${max} caracteres"),
    district: yup
      .string()
      .required("Informe seu bairro")
      .max(60, "Limite de ${max} caracteres"), // bairro
    city: yup
      .string()
      .required("Informe sua cidade")
      .max(60, "Limite de ${max} caracteres")
      .min(2, "Mínimo de ${min} caracteres"),
    state: yup
      .string()
      .required("Informe a sigla do seu estado")
      .max(2, "Limite de ${max} caracteres, sigla do estado"), // Sigla (UF)
    postalCode: yup
      .string()
      .required("Informe seu CEP")
      .matches(/^[0-9\s]+$/, "Apenas números")
      .max(20, "Devem ser exatamente ${max} números")
      .min(8, "Devem ser exatamente ${min} números"),
    docValue: yup
      .string()
      .required("Informe seu Nome")
      .matches(/^[0-9\s]+$/, "Apenas números"), // @TODO fazer validação, verificar se é apenas para CPF
    cardHolder: yup
      .string()
      .required("Informe o nome expresso no cartão")
      .max(50, "Limite de ${max} caracteres"),
    birthDate: yup.string().required("Informe sua data de nascimento"), // @TODO verificar formato
    cardNumber: yup
      .string()
      .required("Informe o numero do cartão")
      .matches(/^[0-9\s]+$/, "Apenas números"),
    cardCvv: yup
      .string()
      .required("Informe o CVV / Código de segurança")
      .matches(/^[0-9\s]+$/, "Apenas números"),
    cardExpirationMonth: yup
      .string()
      .required("Informe o mes de expiração")
      .matches(/^[0-9\s]+$/, "Apenas números")
      .max(2, "Números entre 01 e 12")
      .min(2, "Números entre 01 e 12"),
    cardExpirationYear: yup
      .string()
      .required("Informe o ano de expiração")
      .matches(/^[0-9\s]+$/, "Apenas números")
      .max(4, "Ano completo, ex: 1999")
      .min(4, "Ano completo, ex: 1999"),
  });

  const getIp = async () => {
    const data = await axios.get("https://api.ipify.org?format=json");
    form.setFieldValue("ip", data.data.ip);
  };

  const handleCEP = async () => {
    const cepInput = document.getElementById("cep") as HTMLInputElement;
    const formatedValue = cepInput?.value
      .split("")
      .filter((element) => element.match(/^[0-9\s]+$/))
      .join("");

    const add = await axios.get(
      `https://viacep.com.br/ws/${formatedValue || 0}/json/`
    );
    await form.setValues({
      ...form.values,
      postalCode: formatedValue,
      street: add.data.logradouro,
      complement: add.data.complemento,
      district: add.data.bairro,
      city: add.data.localidade,
      state: add.data.uf,
    });
    // form.handleChange("postalCode");
    form.handleBlur(cepInput);
  };

  useEffect(() => {
    getIp();
  }, []);

  const form = useFormik({
    initialValues: {
      name: "",
      email: "",
      ip: "",
      phone: "",
      street: "",
      number: "",
      complement: "",
      district: "", // bairro
      city: "",
      state: "", // Sigla (UF)
      postalCode: "",
      docValue: "",
      cardHolder: "",
      birthDate: "",
      cardNumber: "",
      cardCvv: "",
      cardExpirationMonth: "",
      cardExpirationYear: "",
    },
    initialTouched: { postalCode: true },
    validationSchema: validationSchema,
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        enqueueSnackbar(
          "Enviado informações, aguarde enquanto verificamos seus dados!",
          {
            variant: "info",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }
        );
        await acceptPlan(values);
        if (acceptPlanReponse.isError) {
          throw acceptPlanReponse.error;
        }
        enqueueSnackbar("Tudo certo! Plano atualizado com sucesso", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          autoHideDuration: 1000,
        });
        setTimeout(() => navigate("/"), 1500);
      } catch (err: any) {
        enqueueSnackbar(
          err.data.message ||
            "Ocorreu um erro, por favor verifique as informações!",
          {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            autoHideDuration: 3000,
          }
        );
        console.error(err);
      }
      // await rmv("");
      // localStorage.removeItem("token");
      // window.location.reload();
    },
  });

  return (
    <div id="payment-screen">
      <Screen>
        <Typography variant="h5">Atualize seu plano</Typography>
        <Typography variant="subtitle1">
          Preencha seus dados pessoais e de pagamento para finalizar o pedido.
        </Typography>
        <Grid
          container
          component={"form"}
          onSubmit={form.handleSubmit}
          spacing={2}
          mt={3}
        >
          {/* <form> */}
          <input type={"hidden"} name={"ip"} />
          <Grid item xs={12} md={6}>
            <Paper>
              <Grid container>
                {/* <Box className="blue-bg"></Box> */}
                <Grid item xs={1}></Grid>
                <Grid container item xs={10} spacing={2}>
                  <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <AccountCircleIcon />
                      <Typography variant={"h5"}>Dados Pessoais</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="name"
                      label="Nome completo"
                      size="small"
                      fullWidth
                      // disabled={loading}
                      // InputProps={{
                      //   startAdornment: <MailIcon fontSize="small"/>,
                      // }}
                      value={form.values.name}
                      onChange={form.handleChange}
                      error={form.touched.name && Boolean(form.errors.name)}
                      helperText={form.touched.name && form.errors.name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="email"
                      label="Email"
                      size="small"
                      fullWidth
                      // disabled={loading}
                      // InputProps={{
                      //   startAdornment: <MailIcon fontSize="small"/>,
                      // }}
                      value={form.values.email}
                      onChange={form.handleChange}
                      error={form.touched.email && Boolean(form.errors.email)}
                      helperText={form.touched.email && form.errors.email}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="birthDate"
                      label="Data de Nascimento"
                      size="small"
                      fullWidth
                      type="date"
                      // label="Data de Nascimento"
                      InputLabelProps={{ shrink: true }}
                      // disabled={loading}
                      // InputProps={{
                      //   startAdornment: <MailIcon fontSize="small"/>,
                      // }}
                      value={form.values.birthDate}
                      onChange={form.handleChange}
                      error={
                        form.touched.birthDate && Boolean(form.errors.birthDate)
                      }
                      helperText={
                        form.touched.birthDate && form.errors.birthDate
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="phone"
                      label="Telefone"
                      size="small"
                      fullWidth
                      // disabled={loading}
                      // InputProps={{
                      //   startAdornment: <MailIcon fontSize="small"/>,
                      // }}
                      value={form.values.phone}
                      onChange={form.handleChange}
                      error={form.touched.phone && Boolean(form.errors.phone)}
                      helperText={form.touched.phone && form.errors.phone}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="docValue"
                      label="CPF / CNPJ"
                      size="small"
                      fullWidth
                      // disabled={loading}
                      // InputProps={{
                      //   startAdornment: <MailIcon fontSize="small"/>,
                      // }}
                      value={form.values.docValue}
                      onChange={form.handleChange}
                      error={
                        form.touched.docValue && Boolean(form.errors.docValue)
                      }
                      helperText={form.touched.docValue && form.errors.docValue}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="postalCode"
                      id="cep"
                      label="CEP"
                      size="small"
                      fullWidth
                      // disabled={loading}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                            target="_blank"
                          >
                            <Search fontSize="small" />
                          </IconButton>
                        ),
                      }}
                      value={form.values.postalCode}
                      onBlur={handleCEP}
                      onChange={form.handleChange} // Usar API pra buscar informações do CEP no onBlur
                      error={
                        form.touched.postalCode &&
                        Boolean(form.errors.postalCode)
                      }
                      helperText={
                        form.touched.postalCode && form.errors.postalCode
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="street"
                      label="Endereço"
                      size="small"
                      fullWidth
                      disabled={!form.values.postalCode}
                      // InputProps={{
                      //   startAdornment: <MailIcon fontSize="small"/>,
                      // }}
                      value={form.values.street}
                      onChange={form.handleChange}
                      error={form.touched.street && Boolean(form.errors.street)}
                      helperText={
                        (form.touched.street && form.errors.street) ||
                        (!form.values.postalCode && "Digite o CEP para liberar")
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="number"
                      label="Numero"
                      size="small"
                      fullWidth
                      disabled={!form.values.postalCode}
                      // InputProps={{
                      //   startAdornment: <MailIcon fontSize="small"/>,
                      // }}
                      value={form.values.number}
                      onChange={form.handleChange}
                      error={form.touched.number && Boolean(form.errors.number)}
                      helperText={
                        (form.touched.number && form.errors.number) ||
                        (!form.values.postalCode && "Digite o CEP para liberar")
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="complement"
                      label="Complemento"
                      size="small"
                      fullWidth
                      disabled={!form.values.postalCode}
                      // InputProps={{
                      //   startAdornment: <MailIcon fontSize="small"/>,
                      // }}
                      value={form.values.complement}
                      onChange={form.handleChange}
                      error={
                        form.touched.complement &&
                        Boolean(form.errors.complement)
                      }
                      helperText={
                        (form.touched.complement && form.errors.complement) ||
                        (!form.values.postalCode && "Digite o CEP para liberar")
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="district"
                      label="Bairro"
                      size="small"
                      fullWidth
                      disabled={!form.values.postalCode}
                      // InputProps={{
                      //   startAdornment: <MailIcon fontSize="small"/>,
                      // }}
                      value={form.values.district}
                      onChange={form.handleChange}
                      error={
                        form.touched.district && Boolean(form.errors.district)
                      }
                      helperText={
                        (form.touched.district && form.errors.district) ||
                        (!form.values.postalCode && "Digite o CEP para liberar")
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="city"
                      label="Cidade"
                      size="small"
                      fullWidth
                      disabled={!form.values.postalCode}
                      // InputProps={{
                      //   startAdornment: <MailIcon fontSize="small"/>,
                      // }}
                      value={form.values.city}
                      onChange={form.handleChange}
                      error={form.touched.city && Boolean(form.errors.city)}
                      helperText={
                        (form.touched.city && form.errors.city) ||
                        (!form.values.postalCode && "Digite o CEP para liberar")
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="state"
                      label="Sigla estado"
                      size="small"
                      fullWidth
                      disabled={!form.values.postalCode}
                      // InputProps={{
                      //   startAdornment: <MailIcon fontSize="small"/>,
                      // }}
                      value={form.values.state}
                      onChange={form.handleChange}
                      error={form.touched.state && Boolean(form.errors.state)}
                      helperText={
                        (form.touched.state && form.errors.state) ||
                        (!form.values.postalCode && "Digite o CEP para liberar")
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper>
              <Grid container>
                <Grid item xs={1}></Grid>
                {/* <Box className="blue-bg">fundo azul</Box> */}
                <Grid container item xs={10} spacing={2}>
                  <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <CreditCardIcon />
                      <Typography variant={"h5"}>Forma de pagamento</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="cardHolder"
                      label="Nome do titular do cartão"
                      size="small"
                      fullWidth
                      // disabled={loading}
                      // InputProps={{
                      //   startAdornment: <MailIcon fontSize="small"/>,
                      // }}
                      value={form.values.cardHolder}
                      onChange={form.handleChange}
                      error={
                        form.touched.cardHolder &&
                        Boolean(form.errors.cardHolder)
                      }
                      helperText={
                        form.touched.cardHolder && form.errors.cardHolder
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="cardNumber"
                      label="Número do cartão"
                      size="small"
                      fullWidth
                      // disabled={loading}
                      // InputProps={{
                      //   startAdornment: <MailIcon fontSize="small"/>,
                      // }}
                      value={form.values.cardNumber}
                      onChange={form.handleChange}
                      error={
                        form.touched.cardNumber &&
                        Boolean(form.errors.cardNumber)
                      }
                      helperText={
                        form.touched.cardNumber && form.errors.cardNumber
                      }
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <TextField
                      name="cardExpirationMonth"
                      label="MM"
                      size="small"
                      fullWidth
                      // disabled={loading}
                      // InputProps={{
                      //   startAdornment: <MailIcon fontSize="small"/>,
                      // }}
                      value={form.values.cardExpirationMonth}
                      onChange={form.handleChange}
                      error={
                        form.touched.cardExpirationMonth &&
                        Boolean(form.errors.cardExpirationMonth)
                      }
                      helperText={
                        form.touched.cardExpirationMonth &&
                        form.errors.cardExpirationMonth
                      }
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <TextField
                      name="cardExpirationYear"
                      label="AAAA"
                      size="small"
                      fullWidth
                      // disabled={loading}
                      // InputProps={{
                      //   startAdornment: <MailIcon fontSize="small"/>,
                      // }}
                      value={form.values.cardExpirationYear}
                      onChange={form.handleChange}
                      error={
                        form.touched.cardExpirationYear &&
                        Boolean(form.errors.cardExpirationYear)
                      }
                      helperText={
                        form.touched.cardExpirationYear &&
                        form.errors.cardExpirationYear
                      }
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <TextField
                      name="cardCvv"
                      label="CVV"
                      size="small"
                      fullWidth
                      // disabled={loading}
                      // InputProps={{
                      //   startAdornment: <MailIcon fontSize="small"/>,
                      // }}
                      value={form.values.cardCvv}
                      onChange={form.handleChange}
                      error={
                        form.touched.cardCvv && Boolean(form.errors.cardCvv)
                      }
                      helperText={form.touched.cardCvv && form.errors.cardCvv}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Você está atualizando para o plano:</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={1}
                      id="medalStack"
                    >
                      <WorkspacePremiumIcon />
                      <Typography variant={"h6"}>Premium</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    Para quem busca alto desempenho
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={"h5"} sx={{ fontWeight: 700 }}>
                      R$ 99.99 / Mês
                    </Typography>
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <Button type="submit" variant="contained">
                      Atualizar para o Premium por R$ 99.99 / Mês
                    </Button>
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    <Grid container alignItems={"center"}>
                      <Grid item xs={7}>
                        <Typography>Pagamento processado pelo</Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <img
                          className="confirm-image"
                          src={LogoPagSeguro}
                          alt="Imagem de email confirmado"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {/* </form> */}
        </Grid>
      </Screen>
    </div>
  );
}
