import {
  Button,
  ButtonBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import StarterIcon from "@mui/icons-material/Flag";
import PremiumIcon from "@mui/icons-material/WorkspacePremium";
import SupportIcon from "@mui/icons-material/SupportAgent";
import FeatureOffIcon from "@mui/icons-material/CloseOutlined";
import FeatureOnIcon from "@mui/icons-material/CheckCircle";

import "./styles.sass";
import Screen from "../../components/Screen";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {}

export default function Plans({}: Props) {
  const navigate = useNavigate();

  return (
    <div className="plans-screen">
      <Screen>
        <Typography variant="h5">Mude seu plano!</Typography>
        <Typography variant="subtitle1">
          Selecione uma opção e faça upgrade para atender às suas necessidades.
        </Typography>

        <div className="plans-list">
          <Paper elevation={3} className="plan royal">
            <Typography variant="subtitle2" className="name">
              <StarterIcon />
              Starter
            </Typography>
            <Typography variant="subtitle2" className="subtitle">
              Inclui as funcionalidades básicas do Assis.
            </Typography>
            <div className="price">
              <Typography variant="h4" className="value">
                R$ 0
              </Typography>
              <Typography variant="body1" className="description">
                Por usuário /Mês
              </Typography>
            </div>
            <Button variant="contained" disabled className="action-button">
              Plano atual
            </Button>
            <List dense className="features">
              <ListItem disabled>
                <ListItemIcon>
                  <FeatureOffIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Análises ilimitadas" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Grupos de risco" />
              </ListItem>
              <ListItem disabled>
                <ListItemIcon>
                  <FeatureOffIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Perfil do aluno concludente/evasor" />
              </ListItem>
              <ListItem disabled>
                <ListItemIcon>
                  <FeatureOffIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Métricas comportamentais das turmas" />
              </ListItem>
              <ListItem disabled>
                <ListItemIcon>
                  <FeatureOffIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Indicadores de potencial risco de evasão" />
              </ListItem>
              <ListItem disabled>
                <ListItemIcon>
                  <FeatureOffIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Integração" />
              </ListItem>
              <ListItem disabled>
                <ListItemIcon>
                  <FeatureOffIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Consultoria" />
              </ListItem>
              <ListItem disabled>
                <ListItemIcon>
                  <FeatureOffIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Treinamentos" />
              </ListItem>
            </List>
          </Paper>

          <Paper elevation={3} className="plan yellow">
            <Typography variant="subtitle2" className="name">
              <PremiumIcon />
              Premium
            </Typography>
            <Typography variant="subtitle2" className="subtitle">
              Inclui todas as funcionalidades do Assis.
            </Typography>
            <div className="price">
              <Typography variant="h4" className="value">
                R$ 99
              </Typography>
              <Typography variant="body1" className="description">
                Por usuário /Mês
              </Typography>
            </div>
            <Button
              variant="contained"
              className="action-button"
              onClick={() => {
                navigate("/plans/payment");
              }}
            >
              Mudar para o premium
            </Button>
            <List dense className="features">
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Análises ilimitadas" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Grupos de risco" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Perfil do aluno concludente/evasor" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Métricas comportamentais das turmas" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Indicadores de potencial risco de evasão" />
              </ListItem>
              <ListItem disabled>
                <ListItemIcon>
                  <FeatureOffIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Integração" />
              </ListItem>
              <ListItem disabled>
                <ListItemIcon>
                  <FeatureOffIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Consultoria" />
              </ListItem>
              <ListItem disabled>
                <ListItemIcon>
                  <FeatureOffIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Treinamentos" />
              </ListItem>
            </List>
          </Paper>

          <Paper elevation={3} className="plan purple">
            <Typography variant="subtitle2" className="name">
              <SupportIcon />
              Suporte total
            </Typography>
            <Typography variant="subtitle2" className="subtitle">
              Inclui todas as funcionalidades do Assis + suporte dos
              especialistas.
            </Typography>
            <div className="price">
              <Typography variant="h4" className="value">
                Entre em contato
              </Typography>
              {/* <Typography variant="body1" className="description">
                Por usuário /Mês
              </Typography> */}
            </div>
            <Button
              variant="contained"
              className="action-button"
              onClick={() => {
                navigate("/plans/payment");
              }}
            >
              Mudar para Suporte total
            </Button>
            <List dense className="features">
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Análises ilimitadas" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Grupos de risco" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Perfil do aluno concludente/evasor" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Métricas comportamentais das turmas" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Indicadores de potencial risco de evasão" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Integração" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Consultoria" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FeatureOnIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Treinamentos" />
              </ListItem>
            </List>
          </Paper>
        </div>
      </Screen>
    </div>
  );
}
