import {
  Button,
  ButtonBase,
  ButtonProps,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import {
  Home as HomeIcon,
  Paid as PaymentsIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import Logo from "../../global/images/logo.svg";
import "./styles.sass";
import { useNavigate } from "react-router-dom";

interface Props extends ButtonProps {}

export default function MainSidebar({}: Props) {
  const navigate = useNavigate();
  return (
    <Paper elevation={3} className="main-sidebar">
      <img className="logo" src={Logo} alt="Logo Assis" />

      <List className="menu">
        <ListItem id="sidebar-panel" disablePadding>
          <ListItemButton
            className="menu-button"
            onClick={() => {
              navigate("/i");
            }}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <Typography variant="subtitle2">Painel de controle</Typography>
          </ListItemButton>
        </ListItem>

        <ListItem id="sidebar-billing" disablePadding>
          <ListItemButton
            className="menu-button"
            onClick={() => {
              navigate("/invoices");
            }}
          >
            <ListItemIcon>
              <PaymentsIcon />
            </ListItemIcon>
            <Typography variant="subtitle2">
              Faturamento e pagamentos
            </Typography>
          </ListItemButton>
        </ListItem>

        <ListItem id="sidebar-userSettings" disablePadding>
          <ListItemButton
            className="menu-button"
            onClick={() => {
              navigate("/user/settings");
            }}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <Typography variant="subtitle2">
              Configurações de usuário
            </Typography>
          </ListItemButton>
        </ListItem>
      </List>

      <div id="sidebar-plan">
        <ButtonBase
          className="call-to-plan"
          onClick={() => {
            navigate("/plans");
          }}
        >
          <Typography variant="subtitle2">
            Atualize seu plano para ter acesso a todos os recursos do Assis
          </Typography>
          <Typography variant="h6" className="now">
            Atualizar agora!
          </Typography>
        </ButtonBase>
      </div>
    </Paper>
  );
}
