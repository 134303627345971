import { Paper } from "@mui/material";
import Breadcrumbs from "../Breadcrumbs";
import { ReactNode, useEffect } from "react";

import "./styles.sass";
import { useTheme } from "@emotion/react";
import { useLocation } from "react-router-dom";
import { ReactRoutesState } from "../../types";

interface Props {
  pageTitle?: string;
  children: ReactNode;
  showBreadcrumb?: boolean;
}

export default function Screen({
  // pageTitle,
  children,
  showBreadcrumb = true,
}: Props) {
  const localtion = useLocation();
  const state = localtion.state as ReactRoutesState;

  useEffect(() => {
    // if (pageTitle) document.title = pageTitle;
    document.title = `${process.env.REACT_APP_TITLE}${
      state?.pageTitle ? " > " + state.pageTitle : ""
    }`;
  }, []);

  return (
    <div className="screen">
      {showBreadcrumb && (
        <Paper className="screen-header" elevation={3}>
          <Breadcrumbs />
        </Paper>
      )}
      <Paper className="screen-body" elevation={3}>
        {children}
      </Paper>
    </div>
  );
}
