import Screen from "../../components/Screen";
import "./styles.sass";
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  Typography,
} from "@mui/material";
import DocIcon from "@mui/icons-material/OpenInNew";
import DownloadIcon from "@mui/icons-material/Download";
import BatchUpload from "../../components/BatchUpload";
import { Box } from "@mui/system";
import { useState } from "react";

interface Props {}

export default function NewAnalysis({}: Props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div className="new-analysis-screen">
      <Screen>
        <Typography variant="h6" className="title">
          Nova análise
        </Typography>

        <div className="main">
          <div className="links">
            <Button onClick={handleOpen}>
              Documentação
              <DocIcon fontSize="small" />
            </Button>
            <Button
              download
              target={"_blank"}
              href="https://firebasestorage.googleapis.com/v0/b/assis-console-dev.appspot.com/o/dev%2Fexample.json?alt=media&token=a7933ce9-9944-48ce-acf0-6dcfd725d1f6"
            >
              Exemplo de envio
              <DownloadIcon fontSize="small" />
            </Button>
          </div>
          <div className="file-upload-wrapper">
            <BatchUpload />
          </div>
        </div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            Entre em contato com o time do Assis pelo email abaixo
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="modal-modal-description">
              Email: contato@assis.ai
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Screen>
    </div>
  );
}
