import Screen from "../../components/Screen";
import {
  DataGrid,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";

import {
  useLocation,
  useNavigate,
  useParams,
  useResolvedPath,
} from "react-router-dom";
import { Button, IconButton, Typography } from "@mui/material";
import NewAnalysisIcon from "@mui/icons-material/AddCircleOutline";
import EmptyImg from "./images/empty.png";
import "./styles.sass";
import { useSnackbar } from "notistack";
import { Batch, ReactRoutesState } from "../../types";
import { title } from "process";
import { useGetIntanceBatchsQuery } from "../../services/AssisApi";
import { useEffect, useState } from "react";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { downloadFile } from "../../services/Firebase";
import { Visibility } from "@mui/icons-material";

interface Props {}

type Row = {
  id: string;
  date: string;
  sendType: string;
  processStatus: string;
  info: string;
  file: string;
};

export default function Instance({}: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  let { instanceId } = useParams();
  const state = location.state as ReactRoutesState;
  const [rows, setRows] = useState<Row[]>([]);
  const { data, error, isLoading, refetch } = useGetIntanceBatchsQuery(
    instanceId || "",
    { refetchOnMountOrArgChange: true }
  );

  const columns = [
    {
      field: "see",
      headerName: "",
      renderCell: (params: GridRenderCellParams<string>) => (
        <>
          <IconButton>
            <Visibility fontSize="small" color="primary" />
          </IconButton>
        </>
      ),
    },
    {
      field: "date",
      headerName: "Data",
      width: 150,
    },
    {
      field: "sendType",
      headerName: "Modo de envio",
      width: 150,
    },
    {
      field: "info",
      headerName: "Informações do envio",
      width: 200,
    },
    {
      field: "processStatus",
      headerName: "Status",
      width: 300,
    },
    {
      field: "id",
      headerName: "",
      renderCell: (params: GridRenderCellParams<string>) => (
        <>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              downloadFile(params.row.file);
            }}
          >
            <DownloadIcon fontSize="small" color="primary" />
          </IconButton>
          <IconButton
            color="error"
            onClick={(event) => {
              event.stopPropagation();
              navigate(`${params.value}/delete-confirm`, {
                state: { backgroundLocation: location },
              });
            }}
          >
            <DeleteIcon fontSize="small" color="error" />
          </IconButton>
        </>
      ),
    },
  ];

  const handleRowClick = (params: GridRowParams) => {
    navigate(`${params.id}/result`);
  };

  useEffect(() => {
    if (data) {
      let rows: Row[] = [];
      data.forEach((batch) => {
        rows.push({
          id: batch._id,
          date: moment(batch.startAt).format("DD/MM/YYYY HH:mm"),
          sendType: "Upload de arquivo",
          processStatus: batch.response,
          info: `${batch.courseCount} cursos, ${batch.tutorCount} tutors e ${batch.studentCount} alunos`,
          file: batch.file,
        });
        setRows(rows);
      });
    }
  }, [data]);

  return (
    <div className="instance-screen">
      <Screen>
        <div className="page-header">
          <Typography variant="h6" className="title">
            {state?.pageTitle}
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              navigate("new");
            }}
          >
            <NewAnalysisIcon />
            Nova análise
          </Button>
        </div>
        {rows.length === 0 && (
          <img
            src={EmptyImg}
            className="empty-img"
            alt="Nenhuma análise feita"
          />
        )}
        {rows.length > 0 && (
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            onRowClick={handleRowClick}
            // checkboxSelection
            disableSelectionOnClick
            disableColumnSelector
          />
        )}
      </Screen>
    </div>
  );
}
