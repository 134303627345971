import {
  Alert,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import UploadIcon from "@mui/icons-material/CloudUpload";
import "./styles.sass";
import { FileUploader } from "react-drag-drop-files";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useNewBatchMutation } from "../../services/AssisApi";
import {
  UploadBatch,
  UploadCourse,
  UploadStudent,
  UploadTutor,
} from "../../types";
import { RTKFetchError } from "../../services/Helpers";

const fileTypes = ["JSON"];
interface Props {}
export default function BatchUpload({}: Props) {
  let { instanceId } = useParams();
  const navigate = useNavigate();

  const [file, setFile] = useState({} as File);
  const [fileError, setFileError] = useState("");
  const [fileContent, setFileContent] = useState<UploadBatch>();

  const [newBatch, { isLoading, isError, data, error, reset }] =
    useNewBatchMutation();

  const handleChange = (file: File) => {
    setFileError("");
    setFile(file);
    let reader = new FileReader();
    reader.onload = function (event: any) {
      const fileContent = JSON.parse(event.target.result) as UploadBatch;

      const uniqueCoursesArr: UploadCourse[] = [];
      const uniqueTutorsArr: UploadTutor[] = [];
      const uniqueStudentsArr: UploadStudent[] = [];

      fileContent.courses.forEach((course) => {
        if (!uniqueCoursesArr.some((uItem) => uItem.id === course.id)) {
          uniqueCoursesArr.push(course);
        }
      });

      fileContent.tutors.forEach((tutor) => {
        if (!uniqueTutorsArr.some((uItem) => uItem.id === tutor.id)) {
          uniqueTutorsArr.push(tutor);
        }
      });

      fileContent.students.forEach((student) => {
        if (!uniqueStudentsArr.some((uItem) => uItem.id === student.id)) {
          uniqueStudentsArr.push(student);
        }
      });

      setFileContent({
        courses: uniqueCoursesArr,
        tutors: uniqueTutorsArr,
        students: uniqueStudentsArr,
      });
    };
    reader.readAsText(file);
  };

  const onTypeError = (error: string) => {
    setFileError("Arquivo inválido. Selecione um arquivo no formato JSON");
  };

  const confirmSend = async () => {
    const formData = new FormData();
    formData.append("file", file);
    await newBatch({ instanceId: instanceId as string, formData });
  };

  const cancelSend = async () => {
    setFile({} as File);
    setFileContent(undefined);
  };

  useEffect(() => {
    if (data) {
      // setFile({} as File);
      // setFileContent(undefined);
      navigate(-1);
    }
  }, [data]);

  return (
    <div className="batch-upload">
      {fileError && (
        <Alert
          className="error"
          severity="warning"
          onClose={(event) => {
            event.stopPropagation();
            setFileError("");
          }}
        >
          {fileError}
        </Alert>
      )}
      {isError && (
        <Alert
          className="error-message"
          severity="error"
          onClose={(event) => {
            event.stopPropagation();
            reset();
          }}
        >
          {RTKFetchError(error)?.message}
        </Alert>
      )}

      {data && (
        <Alert
          className="success-message"
          severity="success"
          onClose={(event) => {
            event.stopPropagation();
            reset();
          }}
        >
          {data.message}
        </Alert>
      )}

      {/* {fileContent &&
        fileContent.courses &&
        fileContent.courses.map((course: Course) => <div>{course.name}</div>)} */}

      {fileContent && (
        <div className="batch-upload-confirm-info">
          <div className="buttons">
            <Button
              variant="contained"
              className="confirm-button"
              onClick={confirmSend}
            >
              Confirmar envio
            </Button>
            <Button
              variant="contained"
              color="error"
              className="cancel-button"
              onClick={cancelSend}
            >
              Cancelar
            </Button>
          </div>
          <TableContainer component={Paper} sx={{ maxHeight: 150 }}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>{fileContent.courses.length} Curso(s)</TableCell>
                  <TableCell align="right">
                    {fileContent.tutors.length} Tutor(es)
                  </TableCell>
                  <TableCell align="right">
                    {fileContent.students.length} Aluno(s)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fileContent.courses.map((course) => (
                  <TableRow key={course.id}>
                    <TableCell>{course.name}</TableCell>
                    <TableCell align="right">{course.tutors.length}</TableCell>
                    <TableCell align="right">
                      {course.students.length}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}

      {fileContent === undefined && (
        <FileUploader
          types={fileTypes}
          handleChange={handleChange}
          onTypeError={onTypeError}
        >
          <UploadIcon fontSize="large" className="icon" />
          <Typography variant="body1" className="title">
            Solte seus arquivos aqui ou
          </Typography>
          <Button variant="contained">Selecione um arquivo pra enviar</Button>
          <Typography variant="body2">
            Para uma operação confiável, anexe um arquivo de tamanho menor que
            1GB.
          </Typography>
        </FileUploader>
      )}
    </div>
  );
}
