import "./styles.sass";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  useEvasionMutation,
  useMetricsMutation,
  useIndicatorsMutation,
  useSuggestionsMutation,
} from "../../services/AssisApi";
import { Course } from "../../types";
import AnalysisResultContent from "../AnalysisResultContent";

interface Props {
  batchId: string;
  course: Course;
}

const Component = ({ batchId, course }: Props) => {
  const [evasion, evasionResult] = useEvasionMutation();
  const [metrics, metricsResult] = useMetricsMutation();
  const [indicators, indicatorsResult] = useIndicatorsMutation();
  const [suggestions, suggestionsResult] = useSuggestionsMutation();

  const getCourseData = async (courseId: number) => {
    await evasion({
      batchId,
      courseIds: [courseId],
    });
    await metrics({
      batchId,
      courseIds: [courseId],
    });
    await indicators({
      batchId,
      courseIds: [courseId],
    });
    await suggestions({
      batchId,
      courseIds: [courseId],
    });
  };

  return (
    <div className="analysis-result-course">
      {/* {courses?.map((course) => ( */}
      <Accordion
        className="accordion"
        onChange={() => !evasionResult?.data && getCourseData(course.courseId)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant={"h5"} color={"primary"}>
            {course.name}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion-content">
          {(evasionResult.isLoading ||
            metricsResult.isLoading ||
            indicatorsResult.isLoading ||
            suggestionsResult.isLoading) && (
            <CircularProgress className="loading" size={36} />
          )}

          {evasionResult?.data &&
            suggestionsResult?.data &&
            indicatorsResult?.data &&
            metricsResult?.data && (
              <AnalysisResultContent
                courseCount={evasionResult.data.courseCount}
                tutorCount={evasionResult.data.tutorCount}
                studentCount={evasionResult.data.studentCount}
                evasionData={evasionResult.data}
                suggestionsData={suggestionsResult.data}
                indicatorsData={indicatorsResult.data}
                metricsData={metricsResult.data}
              />
            )}
        </AccordionDetails>
      </Accordion>
      {/* ))} */}
    </div>
  );
};

export default Component;
